export default [
  '/purpose',
  '/steeringCommittee',
  '/DGAB',
  '/BPSC',
  '/crdc',
  '/icdc-data-model',
  '/developers',
  '/support',
  '/submit',
  '/guideline',
];
