// --------------- Dashboard Sidebar Sections styling --------------
export const facetSectionVariables = {
  'Filter By Cases': {
    isExpanded: false,
  },
  'Filter By Samples': {
    isExpanded: false,
  },
  'Filter By Files': {
    isExpanded: false,
  },
};

export const query = {
};
