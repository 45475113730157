import * as React from 'react';

const ReadMoreSVG = (props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_124_463)">
      <path
        d="M16.9473 0.105255H3.05259C1.36838 0.105255 0.105225 1.36841 0.105225 3.05262V16.9474C0.105225 18.6316 1.36838 19.8947 3.05259 19.8947H16.9473C18.6315 19.8947 19.8947 18.6316 19.8947 16.9474V3.05262C19.8947 1.36841 18.6315 0.105255 16.9473 0.105255ZM18 16.9474C18 17.5789 17.5789 17.8947 17.0526 17.8947H3.05259C2.42101 17.8947 2.10522 17.4737 2.10522 16.9474V3.05262C2.10522 2.42104 2.42101 2.10526 3.05259 2.10526H16.9473C17.5789 2.10526 17.8947 2.52631 17.8947 3.05262L18 16.9474Z"
        fill="#1D79A8"
      />
      <path
        d="M11.0526 13.1579H8.94736C8.94736 12.8421 8.94736 12.6316 8.94736 12.6316C8.94736 12 9.05263 11.3684 9.26315 10.9474C9.47368 10.421 9.89473 9.99999 10.6316 9.36841C11.2632 8.8421 11.6842 8.42104 11.7895 8.31578C12 7.99999 12.1053 7.78947 12.1053 7.47368C12.1053 7.05262 11.8947 6.63157 11.5789 6.31578C11.2632 5.99999 10.7368 5.78947 10.1053 5.78947C9.47368 5.78947 9.05262 5.99999 8.63157 6.31578C8.21052 6.63157 7.99999 7.15789 7.78947 7.89473L5.6842 7.6842C5.78947 6.73683 6.21052 5.89473 6.94736 5.15789C7.6842 4.42104 8.73684 4.10526 9.99999 4.10526C11.3684 4.10526 12.421 4.42104 13.1579 5.15789C13.8947 5.89473 14.3158 6.63157 14.3158 7.57894C14.3158 8.10525 14.2105 8.52631 13.8947 9.05262C13.5789 9.47368 12.9474 10.1053 12 10.9474C11.4737 11.3684 11.1579 11.6842 11.0526 11.8947C11.0526 12 11.0526 12.5263 11.0526 13.1579ZM8.94736 16.2105V13.8947H11.2632V16.2105H8.94736Z"
        fill="#1D79A8"
      />
    </g>
    <defs>
      <clipPath id="clip0_124_463">
        <rect width={20} height={20} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default ReadMoreSVG;
