export default (theme) => ({
  logo: {
    // position: 'absolute',
    // float: 'left',
    // marginTop: '30px',
    width: '96px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    padding: '5px 20px 5px 35px',
    zIndex: '1',
    // paddingLeft: '27px',
    // paddingRight: '27px',
    // borderBottom: '#81a6b9 4px solid',
    // height: '124px',
    // margin: 'auto 27px',
  },
  downloadFileManifestTooltipWrapper: {
    display: 'flex',
    gap: '8px',
  },
  divider: {
    position: 'relative',
    bottom: '17px',
    height: '3px',
    background: '#686F7F',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '2px 28px',
    paddingTop: '60px',
  },
  availableDownloadDropdownBtnStartIcon: {
    margin: '0px',
  },
  logoAndTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '18px',
  },
  readMeBtnRoot: {
    boxShadow: 'none',
    // width: '137px',
    // padding: '1px 8px',
    background: '#fff',
    borderRadius: '8px',
    border: '1px solid #003559',
    '&:hover': {
      backgroundColor: '#fff',
      boxShadow: 'none',
    },
  },
  readMeBtnLabel: {
    color: '#1D79A8',
    fontFamily: 'Lato',
    fontSize: '16px',
    fontWeight: 400,
    fontStyle: 'normal',
  },
  headerTitle: {
    // maxWidth: theme.custom.maxContentWidth,
    // margin: 'auto',
    // float: 'left',
    // marginLeft: '110px',
    // paddingLeft: '3px',
  },
  headerMainTitle: {
    fontFamily: theme.custom.fontFamilySans,
    fontWeight: 'bold',
    letterSpacing: '0.017em',
    color: '#ff8a00',
    fontSize: '30px',
    lineHeight: '18px',
    // paddingLeft: '5px',
    // paddingBottom: '8px',
  },
  marginTop80: {
    // marginTop: '80px',
  },
  downloadFileIcon: {
    width: '20px',
    height: '20px',
    verticalAlign: 'middle',
    margingLeft: '4px',
  },
  downloadFileIconContainer: {
    marginLeft: '13px',
  },
  downloadFileManifestBtn: {
    backgroundColor: '#1A8CCB',
    borderRadius: '8px',
    border: '1px solid #1A8CCB',
    boxShadow: 'none',
    width: '235px',
    fontFamily: 'Lato',
    fontStyle: 'normal',
    // maxWidth: '287px',
    fontWeight: 400,
    fontSize: '16px',
    textTransform: 'none',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#1A8CCB',
      boxShadow: 'none',
    },
  },
  availableDownloadDropdownBtnIsOpen: {
    backgroundColor: '#F2F2F2',
    borderTop: '1px solid #155F97',
    borderRight: '1px solid #155F97',
    borderLeft: '1px solid #155F97',
    borderTopRightRadius: '8px',
    borderTopLeftRadius: '8px',
    borderBottomRightRadius: '0px',
    borderBottomLeftRadius: '0px',
    boxShadow: 'none',
    width: '235px',
    '&:hover': {
      backgroundColor: '#F2F2F2',
      boxShadow: 'none',
    },

  },
  availableDownloadDropdownBtn: {
    backgroundColor: '#F2F2F2',
    border: '1px solid #155F97',
    borderRadius: '8px',
    boxShadow: 'none',
    // width: '235px',
    textWrap: 'nowrap',
    '&:hover': {
      backgroundColor: '#F2F2F2',
      boxShadow: 'none',
    },
  },
  availableDownloadDropdownBtnLabel: {
    color: '#1D79A8',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    fontFamily: 'Lato',
    textTransform: 'capitalize',
    paddingRight: '6px',
  },
  availableDownloadBtn: {
    backgroundColor: '#155F97 !important',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
  },
  btnGrpRoot: {
    border: '1px solid #155F97',
    borderRadius: '8px',
    boxShadow: 'none',
  },
  readMoreIconContainer: {
    // marginLeft: '10px',
  },
  downloadFileManifestTooltip: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '12px',
    color: '#000',
    lineHeight: '15px',
  },
  listItemText: { textWrap: 'balance', padding: '0px' },
  downloadFileManifestInfoIcon: {
    width: '15px',
  },
});
