const COLORS_EVEN = [
  '#AD1919',
  '#1F4B87',
  '#427E99',
  '#C2C1C0',
  '#E8C587',
  '#DA6B2E',
  '#A2C5E2',
];

const COLORS_ODD = [
  '#AD1919',
  '#1F4B87',
  '#427E99',
  '#C2C1C0',
  '#E8C587',
  '#DA6B2E',
  '#A2C5E2',
];

const colors = {
  odd: COLORS_ODD,
  even: COLORS_EVEN,
};

export default colors;
